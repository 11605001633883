import "./modules/lazyload";
import $ from 'jquery'
import {
	getData
} from "./burying-point";

// NO JS Fallbacks
document.querySelector("body").classList.add("js");

// Environmental
if (process.env.NODE_ENV === "development") {
	require("./devonly/dev");
}

window.getPageName = function () {
	return {
		pageName: location.pathname
	}
};

function getUUId(str) {
	return str.replace(/[xy]/g, (c) => {
		let r = (Math.random() * 16) | 0
		let v = c == 'x' ? r : (r & 0x3) | 0x8
		return v.toString(16)
	})
}

function getLastTime() {
	// 获取当前日期
	let currentDate = new Date()

	// 设置小时、分钟和秒数为23:59:59
	currentDate.setHours(23, 59, 59)

	// 获取今天最后一秒的时间戳
	return currentDate.getTime()
}

if (!localStorage.getItem('sessionInfo')) {
	const sessionObj = {
		id: getUUId('xxxxxxxx-xxxx'),
		time: getLastTime()
	}
	localStorage.setItem('sessionInfo', JSON.stringify(sessionObj));
}

// 检查localStorage或sessionStorage中是否存在打开时间
const openTime = localStorage.getItem('pageOpenTime');

if (!openTime) {
	// 如果不存在打开时间，则将当前时间戳存储到localStorage或sessionStorage中
	const currentTime = Date.now();
	localStorage.setItem('pageOpenTime', currentTime);
}

const comAndWeb = document.querySelector("#comAndWeb")
const companyid = comAndWeb.getAttribute("companyid")
const websiteid = comAndWeb.getAttribute("websiteid")
const isPreview = comAndWeb.getAttribute("isPreview")
const pageId = comAndWeb.getAttribute("pageId")
const pageType = comAndWeb.getAttribute("pageType")
const env = comAndWeb.getAttribute("env")
const eventDomain = comAndWeb.getAttribute("eventDomain")
const webSiteVersion = comAndWeb.getAttribute("webSiteVersion")
const envMap = {
	'pro': 'https://eshopprod.fumamx.com',
	'test': 'https://cms.laifuyun.com',
	'alpha': 'https://cmsrelease.laifuyun.com'
}

let data = {
	comAndWeb,
	companyid,
	websiteid,
	isPreview,
	pageId,
	pageType,
	env,
	eventDomain: envMap[env]
	// 事件类型 $pageview：Web浏览页面事件 $WebPageLeave：Web浏览时间事件 $WebClick：Web元素点击事件
	// event: '$pageview',
	// eventDuration: ''
}

localStorage.setItem('pageviewData', JSON.stringify(data))

// if (isPreview === 'false') {
// 	// 埋点 - 数据收集开启
// 	let sensors = require('sa-sdk-javascript');
// 	sensors.init({
// 		server_url: envMap[env],
// 		show_log: true,
// 		is_track_single_page: false, // 单页面配置，默认开启，若页面中有锚点设计，需要将该配置删除，否则触发锚点会多触发 $pageview 事件
// 		use_client_time: true,
// 		send_type: 'beacon',
// 		heatmap: {
// 			//是否开启点击图，default 表示开启，自动采集 $WebClick 事件，可以设置 'not_collect' 表示关闭。
// 			clickmap: 'default',
// 			//是否开启触达图，not_collect 表示关闭，不会自动采集 $WebStay 事件，可以设置 'default' 表示开启。
// 			scroll_notice_map: 'default',
// 			collect_element: function (element_target) {
// 				// 如果这个元素有属性sensors-disable = true时候，不采集
// 				if (element_target.getAttribute('sensors-disable') === 'true') {
// 					return false
// 				} else {
// 					return true
// 				}
// 			}
// 		},
// 		preset_properties: {
// 			//是否采集 $latest_utm 最近一次广告系列相关参数，默认值 true。
// 			latest_utm: true,
// 			//是否采集 $latest_traffic_source_type 最近一次流量来源类型，默认值 true。
// 			latest_traffic_source_type: true,
// 			//是否采集 $latest_search_keyword 最近一次搜索引擎关键字，默认值 true。
// 			latest_search_keyword: true,
// 			//是否采集 $latest_referrer 最近一次前向地址，默认值 true。
// 			latest_referrer: true,
// 			//是否采集 $latest_referrer_host 最近一次前向地址，1.14.8 以下版本默认是true，1.14.8 及以上版本默认是 false，需要手动设置为 true 开启。
// 			latest_referrer_host: true,
// 			//是否采集 $latest_landing_page 最近一次落地页地址，默认值 false。
// 			latest_landing_page: true,
// 			//是否采集 $url 页面地址作为公共属性，1.16.5 以下版本默认是 false，1.16.5 及以上版本默认是 true。
// 			url: true,
// 			//是否采集 $title 页面标题作为公共属性，1.16.5 以下版本默认是 false，1.16.5 及以上版本默认是 true。
// 			title: true,
// 		}
// 	});
// 	// 注册公共属性
// 	sensors.registerPage({
// 		companyid: companyid,
// 		websiteid: websiteid,
// 		pageId: pageId,
// 		pageType: pageType,
// 		event_source: '独立站'
// 	});
// 	sensors.setProfile({
// 		user_source: '独立站'
// 	});
// 	sensors.quick('autoTrack');
// 	sensors.use('PageLeave');
// }

$(function () {
	if ($("#mobileMenuList").length) {
		$("#openMenu").on('click', openMobileMenu);
		$("#closeMenu").on("click", closeMobileMenu);

		function openMobileMenu() {
			$('body').css({
				'overflow': 'hidden'
			})
			$(this).attr('aria-expanded', 'true')
			// $("#mobileMenuList").slideDown()
			$('#mobileMenuList').animate({
				width: 'toggle'
			}, 350);
			setTimeout(function () {
				$("#closeMenu").focus();
			}, 250)
		}

		function closeMobileMenu() {
			$('body').css({
				'overflow': 'scroll'
			})
			$(this).attr('aria-expanded', 'false')
			// $("#mobileMenuList").slideUp()
			$('#mobileMenuList').animate({
				width: 'toggle'
			}, 350);
			setTimeout(function () {
				$("#openMenu").focus();
			}, 250)
		}
	}
	//获取要定位元素距离浏览器顶部的距离
	var navH = $("#fixedMenu").offset().top;
	//滚动条事件
	$(window).scroll(function () {
		//获取滚动条的滑动距离
		var scroH = $(this).scrollTop();
		//滚动条的滑动距离大于等于定位元素距离浏览器顶部的距离，就固定，反之就不固定
		if (scroH >= navH) {
			$("#fixedMenu").css({
				"position": "fixed",
				'top': '0px',
				'width': '100%',
				'z-index': '9',
				'background': 'rgb(255, 255, 255)'
			});
		} else if (scroH < navH) {
			$("#fixedMenu").css({
				"position": "relative"
			});
		}
	})
})

// 这里是全局点击事件监听器的处理逻辑
// document.addEventListener('click', function (event) {
// });

// 为a标签注册点击事件监听器
// let aElements = document.getElementsByTagName("a");
// for (var i = 0; i < aElements.length; i++) {
// 	aElements[i].addEventListener('click', function (event) {
// 		const innerText = event.target.innerText
// 		// 这里是按钮被点击时的处理逻辑
// 		console.log('a标签被点击了', innerText);
// 		// 在这里添加你希望执行的操作
// 	});
// }

// 为按钮注册点击事件监听器
// let buttonElements = document.getElementsByTagName("button");
// for (var i = 0; i < buttonElements.length; i++) {
// 	buttonElements[i].addEventListener('click', function (event) {
// 		const innerText = event.target.innerText

// 		// 这里是按钮被点击时的处理逻辑
// 		console.log('按钮被点击了', innerText);

// 		// 如果是点击询盘的Submit按钮
// 		if (innerText === 'Submit')
// 			getData({
// 				...data,
// 				elementId: 'inquiry submit',
// 				event: '$WebClick'
// 			})
// 	});
// }

// 获取页面停留时间
function beforeunload() {
  let dataCopy = {
		...data
	}
	// 获取之前存储的打开时间
	const unloadOpenTime = localStorage.getItem('pageOpenTime');

	// 发送持续时长数据给服务器或其他处理逻辑
	if (unloadOpenTime) {
		// 计算页面的持续时长
		const duration = (Date.now() - parseInt(unloadOpenTime, 10)) / 1000;

		// 发送持续时长数据给服务器或其他处理逻辑 todo
		if (duration > 3 && duration < 3600) {
			/**
			 * 事件持续时长 
			 * 仅事件类型为$pageview、$WebPageLeave时需传入该字段 单位秒，三位小数
			 * 停留时间超过三秒这添加属性
			 **/
			dataCopy.eventDuration = duration;
			dataCopy.event = '$WebPageLeave';
			getData({
				...dataCopy
			})
		}

		// 重新存储当前的时间戳
		localStorage.setItem('pageOpenTime', Date.now());
	}
}

window.beforeunload = beforeunload();

$(function () {
	const info = {
		...data,
    event: '$pageview'
	}
	getData(info)
})
